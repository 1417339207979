import type { ReactNode } from 'react';

import AppsIcon from '@mui/icons-material/Apps';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import CategoryIcon from '@mui/icons-material/Category';
import ImageIcon from '@mui/icons-material/Image';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { ROUTES } from 'src/router/constants';

export enum FeatureCode {
  MANAGEMENT_LAUNCHER = 'MANAGEMENT_LAUNCHER',
  APP_STORE = '"APP_STORE"',
  LAUNCHER_CODE_TYPE = 'LAUNCHER_CODE_TYPE',
  CODE_DEVICE = 'CODE_DEVICE',
  IMAGE_CAR = 'IMAGE_CAR',
  IMAGE_LAUNCHER = 'IMAGE_LAUNCHER',
  PANORAMIC_LAUNCHER = 'PANORAMIC_LAUNCHER',
  USER = 'USER',
  BANNER = 'BANNER',
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  PRODUCT_WARE_HOUSE = 'PRODUCT_WARE_HOUSE',
  WARE_HOUSE = 'WARE_HOUSE',
  ORDERS = 'ORDERS',
  SYSTEM = 'SYSTEM',
  MERCHANT = 'MERCHANT'
}

export interface SystemRoleMenuModel {
  featureCode: FeatureCode;
  read: boolean;
}
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
  featureCode?: FeatureCode;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Management',
    items: [
      {
        name: 'Quản lý launcher',
        icon: AppsIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.APPS}/${ROUTES.LIST}`,
        featureCode: FeatureCode.MANAGEMENT_LAUNCHER,
        items: [
          {
            featureCode: FeatureCode.APP_STORE,
            name: 'Ứng Dụng Trên tmas store',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.APPS}/${ROUTES.LIST}`
          },
          {
            featureCode: FeatureCode.LAUNCHER_CODE_TYPE,
            name: 'Quản lý loại mã',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.LAUNCHER_CODE_TYPE}/${ROUTES.LIST}`
          },
          {
            featureCode: FeatureCode.CODE_DEVICE,
            name: 'Mã kích hoạt thiết bị',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.LAUNCHER}/${ROUTES.LIST}`
          },
          {
            featureCode: FeatureCode.IMAGE_CAR,
            name: 'Quản lý ảnh xe',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.CAR}/${ROUTES.LIST}`
          },
          {
            featureCode: FeatureCode.IMAGE_LAUNCHER,
            name: 'Quản lý hình nền',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.IMAGE_LAUNCHER}/${ROUTES.LIST}`
          },
          {
            featureCode: FeatureCode.PANORAMIC_LAUNCHER,
            name: 'Quản lý khung cảnh',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.PANORAMIC_LAUNCHER}/${ROUTES.LIST}`
          }
        ]
      },
      {
        featureCode: FeatureCode.USER,
        name: 'Người dùng',
        icon: AssignmentIndTwoToneIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.USERS}/${ROUTES.LIST}`
      },
      {
        featureCode: FeatureCode.BANNER,
        name: 'Banners',
        icon: ImageIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.BANNERS}/${ROUTES.LIST}`
      },
      {
        featureCode: FeatureCode.CATEGORY,
        name: 'Loại hàng hóa',
        icon: CategoryIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.CATEGORIES}/${ROUTES.LIST}`
      },
      {
        featureCode: FeatureCode.PRODUCT,
        name: 'Sản phẩm',
        icon: Inventory2TwoToneIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.PRODUCTS}/${ROUTES.LIST}`
      },
      {
        featureCode: FeatureCode.PRODUCT_WARE_HOUSE,
        name: 'Quản lý hàng trong kho',
        icon: WarehouseIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.PRODUCT_WAREHOUSE}/${ROUTES.LIST}`,
        items: [
          {
            featureCode: FeatureCode.SYSTEM,
            name: 'Kho hệ thống',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.SYSTEM_WAREHOUSE}/${ROUTES.LIST}`
          },
          {
            featureCode: FeatureCode.MERCHANT,
            name: 'Kho chi nhánh',
            link: `/${ROUTES.MANAGEMENT}/${ROUTES.MERCHANT_WAREHOUSE}/${ROUTES.LIST}`
          }
        ]
      },
      {
        featureCode: FeatureCode.WARE_HOUSE,
        name: 'Danh sách kho hàng',
        icon: WarehouseIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.WAREHOUSE}/${ROUTES.LIST}`
      },
      {
        featureCode: FeatureCode.ORDERS,
        name: 'Quản lý đơn hàng',
        icon: ShoppingCartCheckoutIcon,
        link: `/${ROUTES.MANAGEMENT}/${ROUTES.DRAFT_ORDER}/${ROUTES.LIST}`
      }
    ]
  }
];

export default menuItems;

export const isReadMenu = (
  roles: Array<SystemRoleMenuModel>,
  featureCode: FeatureCode
) => {
  return roles?.find((r) => r.featureCode === featureCode)?.read || false;
};
