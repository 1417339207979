import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { ROUTES } from './constants';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ImageLauncher = Loader(
  lazy(() => import('src/content/management/ImageLauncher/index'))
);

const ImageLauncherDetail = Loader(
  lazy(() => import('src/content/management/ImageLauncher/detail'))
);

const imageLauncherRoutes = [
  {
    path: ROUTES.IMAGE_LAUNCHER,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.LIST} replace />
      },
      {
        path: ROUTES.LIST,
        element: <ImageLauncher />
      },
      {
        path: ROUTES.CREATE,
        element: <ImageLauncherDetail />
      }
    ]
  }
];

export default imageLauncherRoutes;
