import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import App from 'src/App';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { setupReactQuery } from './configs/react-query-config';

const queryClient = setupReactQuery();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <Provider store={store}>
        <SidebarProvider>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <ScrollTop />
              <AuthProvider>
                <App />
              </AuthProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </SidebarProvider>
      </Provider>
    </HelmetProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
