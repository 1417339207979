import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
  timeout: 50000
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!'
    )
);

export const authAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
  timeout: 50000
});

authAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!'
    )
);

export const authUserAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USER_API_URL,
  responseType: 'json',
  timeout: 50000
});

authUserAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!'
    )
);

export const userAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USER_API_URL,
  responseType: 'json',
  timeout: 50000
});

userAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!'
    )
);
